import { IdName } from '@/models/Interfaces';
import { Coupon } from '@/models/Provider';
export class Slot {
    id: string;
    endTime: string;
    endTimeView: string;
    startTime: string;
    startTimeView: string;
    duration: string;
    quantity: number;
    isVirtual: boolean;
    constructor() {
        this.id = '';
        this.endTime = '';
        this.endTimeView = '';
        this.startTime = '';
        this.startTimeView = '';
        this.duration = '';
        this.quantity = 0;
        this.isVirtual = false;
    }
}

export class ProductCustomer {
    id: string;
    name: string;
    price: string;
    priceForOne: string;
    quantity: string;
    priceCalculated: boolean;

    constructor() {
        this.id = '';
        this.name = '';
        this.price = '0';
        this.priceForOne = '';
        this.quantity = '';
        this.priceCalculated = true;
    }
}

export class Product {
    active: boolean;
    address: string;
    calendarColor: string;
    city: string;
    cityId: string;
    createdAt: string;
    fullDescription: string;
    id: string;
    latitude: number;
    longitude: number;
    mostPopular: false;
    name: string;
    productCustomerTypes: [];
    productDocuments: [];
    productExcludedTags: [];
    productImages: [];
    productIncludedTags: [];
    productPrices: [];
    productRegions: [];
    productTags: [];
    productTranslations: null;
    productTypeId: string;
    productTypes: null;
    productVideos: [];
    shortDescription: string;
    constructor() {
        this.active = true;
        this.address = '';
        this.calendarColor = '';
        this.city = '';
        this.cityId = '';
        this.createdAt = '';
        this.fullDescription = '';
        //this.id = 1102
        this.id = '';
        this.latitude = 0;
        this.longitude = 0;
        this.mostPopular = false;
        this.name = '';
        this.productCustomerTypes = [];
        this.productDocuments = [];
        this.productExcludedTags = [];
        this.productImages = [];
        this.productIncludedTags = [];
        this.productPrices = [];
        this.productRegions = [];
        this.productTags = [];
        this.productTranslations = null;
        this.productTypeId = '';
        this.productTypes = null;
        this.productVideos = [];
        this.shortDescription = '';
    }
}
export class ProductToAddRequestCustomerItem {
    id = '';
    quantity = 0;
    priceForOne = 0;
    discountAmount = 0;
    couponId: string | null = null;
    date: string | null = null;
    slotId: string | null = null;
}

export class ProductToAddRequestItem {
    productId = '';
    priceId = '';
    slotId = '';
    price = 0;
    date = '';
    startDateTime = '';
    customers: ProductToAddRequestCustomerItem[] = [];
}

export class OrderProduct {
    id: string;
    productId: string;
    name: string;
    date: string;
    startDateTime: string;
    calendarDate: Date | string | null;
    slotId: string;
    slot: Slot;
    customers: ProductCustomer[];
    price: number;
    priceForOne: number;
    currencySign: string;
    currency: string;
    opened: boolean;
    priceName: string;
    product: Product;
    isAvailable = false;
    notAvailableCustomerTypeIds: string[];
    covidInfo: string;
    arrivalInstructions: string;
    parkingInstructions: string;

    constructor() {
        this.id = '';
        this.productId = '';
        this.name = '';
        this.date = '';
        this.startDateTime = '';
        this.calendarDate = null;
        this.slotId = '';
        this.slot = new Slot();
        this.customers = [];
        this.price = 0;
        this.priceForOne = 0;
        this.currencySign = '';
        this.currency = '';
        this.opened = false;
        this.priceName = '';
        this.product = new Product();
        this.notAvailableCustomerTypeIds = [];
        this.covidInfo = '';
        this.arrivalInstructions = '';
        this.parkingInstructions = '';
    }
}

export class OrderProductDetails {
    discountTotal = 0;
    priceTotalWithOutDiscount = 0;
    dateTime = '';
    date = '';
    startTime = '';
    duration = '';
    price = 0;
    arrivalInstructions = '';
    covidInfo = '';
    currencySign = '';
    currencyId = '';
    customers: OrderProductCustomerDetails[] = [];
    calendarDate: Date | string | null = null;
    slotId = '';
    slot: Slot = new Slot();
    productId = '';
    productName = '';
    isAvailable = false;
    notAvailableCustomerTypeIds: string[] = [];
}
export class Order {
    id: string;
    customerGroupName: string | null;
    customerPNR: string | null;
    customerFullName: string;
    customerFirstName: string;
    customerLastName: string;
    mobileNumber: string;
    email: string;
    priceTypeId: string;
    currency: string;
    currencySign: string;
    discountAmount: string;
    discountPercent: number;
    priceWithDiscount: number;
    priceTotal: number;
    products: OrderProductDetails[];
    newproduct: OrderProductDetails;
    remarks: string;
    channelId: string;
    constructor() {
        this.id = '';
        this.customerGroupName = '';
        this.customerPNR = '';
        this.customerFullName = '';
        this.customerLastName = '';
        this.customerFirstName = '';
        this.mobileNumber = '';
        this.email = '';
        this.priceTypeId = '';
        this.currency = '';
        this.currencySign = '';
        this.discountAmount = '0';
        this.discountPercent = 0;
        this.priceWithDiscount = 0;
        this.priceTotal = 0;
        this.products = [];
        this.newproduct = new OrderProductDetails();
        this.remarks = '';
        this.channelId = '';
    }
}
export class Currency {
    culture: string;
    name: string;
    symbol: string;
    constructor() {
        this.culture = '';
        this.name = '';
        this.symbol = '';
    }
}
export class Customer {
    email: string;
    mobile: string;
    firstName: string;
    lastName: string;
    fullName: string;
    pid: string;
    groupName: string;
    pnr: string;
    subscribe: boolean | null;
    constructor() {
        this.email = '';
        this.firstName = '';
        this.lastName = '';
        this.mobile = '';
        this.pid = '';
        this.fullName = '';
        this.groupName = '';
        this.pnr = '';
        this.subscribe = null;
    }
}

export class OrderProvider {
    active: boolean;
    address: '';
    email: string;
    id: string;
    mainImageUrl: string;
    name: string;
    type: number;
    validDate: string;
    constructor() {
        this.email = '';
        this.name = '';
        this.active = false;
        this.address = '';
        this.id = '';
        this.mainImageUrl = '';
        this.type = 0;
        this.validDate = '';
    }
}
export class MyOrderCustomer {
    qty = 0;
    typeName = '';
}
export class MyOrder {
    id: string;
    createDate: string;
    customerFullName: string;
    customerMobile: string;
    orderNumber: number;
    orderProductCreateDate: string;
    orderProductStartDate: string;
    productName: string;
    slotDuration: string;
    slotStartTime: string;
    total: number;
    balance: number;
    invoiceTotal: number;
    receiptTotal: number;
    currencySymbol: string;
    durationMinutes: number;
    haveNewMessages: boolean;
    haveRemarks: boolean;
    arrivalConfirmationId: number;
    orderStatusName: string;
    orderStatusId: number;
    customers: MyOrderCustomer[];
    constructor() {
        this.id = '';
        this.createDate = '';
        this.customerFullName = '';
        this.customerMobile = '';
        this.orderNumber = 0;
        this.orderProductCreateDate = '';
        this.orderProductStartDate = '';
        this.productName = '';
        this.slotDuration = '';
        this.slotStartTime = '';
        this.total = 0;
        this.balance = 0;
        this.invoiceTotal = 0;
        this.receiptTotal = 0;
        this.currencySymbol = '';
        this.durationMinutes = 0;
        this.haveNewMessages = false;
        this.arrivalConfirmationId = 0;
        this.haveRemarks = false;
        this.customers = [];
        this.orderStatusName = '';
        this.orderStatusId = 0;
    }
}
export class OrderDetails {
    priceType: IdName;
    currency: Currency;
    currencySign: string;
    currencySymbol: string;
    currencyId: string;
    leadCustomer: Customer;
    provider: OrderProvider;
    channel: IdName;
    id: string;
    productName: string;
    invoiceTotal: number;
    orderNumber: number;
    discountAmount: number;
    discountPercent: number;
    priceWithDiscount: number;
    priceTotal: number;
    priceWithoutVat: number;
    receiptTotal: number;
    vatAmount: number;
    vatPercent: number;
    createDate: string;
    status: string;
    statusId: number;
    remarks: string;
    priceTotalWithoutDiscount: number;
    messages: OrderMessage[];
    arrivalConfirmationId: number;
    commissionTotal: number;
    commissionPercent: number;
    totalDiscountAmount: number;
    totalToPay: number;
    products: OrderProductDetails[];
    channelId: string;
    priceTypeId: string;
    newproduct: OrderProductDetails;
    businessClientId: number | null;
    businessClientName: string;
    affiliateName: string | null;
    coupons: Coupon[];
    gross = 0;
    net = 0;
    paid = 0;
    ballance = 0;
    customerTotalPrice = 0;
    employeeIds: string[];
    constructor() {
        this.currency = new Currency();
        this.currencySign = '';
        this.currencySymbol = '';
        this.currencyId = '';
        this.leadCustomer = new Customer();
        this.id = '';
        this.productName = '';
        this.discountAmount = 0;
        this.discountPercent = 0;
        this.priceWithDiscount = 0;
        this.priceTotal = 0;
        this.invoiceTotal = 0;
        this.orderNumber = 0;
        this.priceWithoutVat = 0;
        this.receiptTotal = 0;
        this.vatAmount = 0;
        this.vatPercent = 0;
        this.createDate = '';
        this.status = '';
        this.statusId = 0;
        this.priceType = { id: '', name: '' };
        this.provider = new OrderProvider();
        this.products = [];
        this.remarks = '';
        this.priceTotalWithoutDiscount = 0;
        this.messages = [];
        this.arrivalConfirmationId = 0;
        this.channel = { id: '', name: '' };
        this.commissionTotal = 0;
        this.commissionPercent = 0;
        this.totalDiscountAmount = 0;
        this.totalToPay = 0;
        this.products = [];
        this.channelId = '';
        this.priceTypeId = '';
        this.newproduct = new OrderProductDetails();
        this.businessClientId = null;
        this.businessClientName = '';
        this.coupons = [];
        this.affiliateName = null;
        this.employeeIds = [];
    }
}
export class CustomerInfo {
    id = '';
    name = '';
    priceForOne = 0;
    currencySymbol = '';
    coupon: Coupon | null = null;
    couponId: string | null = null;
}

export class OrderProductCustomerDetails {
    couponName = '';
    couponId: string | null = null;
    discountAmount = 0;
    orderProductId = '';
    editMode = false;
    id = '';
    name = '';
    price = 0;
    priceForOne = 0;
    quantity = 0;
    editUnitPrice = 0;
    editDiscountAmount = 0;
    editTotalAmount = 0;
    editQuantity = 0;
    priceCalculated = true;
    date = '';
    slotId = '';
    slot = new Slot();
    limitExceeded = false;
    customerInfo = new CustomerInfo();
}

export class OrderMessage {
    id: string;
    createDate: string;
    from: string;
    isMe: boolean;
    message: string;
    messageValues: Record<string, unknown> | null;
    type: number;
    constructor() {
        this.id = '';
        this.createDate = '';
        this.from = '';
        this.isMe = false;
        this.message = '';
        this.messageValues = null;
        this.type = 0;
    }
}

export class OrdersFilter {
    productId: string;
    slotId: string;
    priceTypeId: string;
    channelId: string;
    orderNumber: string | null;
    status: number | null;
    startDate: string | null;
    endDate: string | null;
    startTime: string | null;
    pageIndex: number;
    keyword: string;
    orderBy: string;
    asc: boolean;
    arrivalStartDate: string | null;
    arrivalEndDate: string | null;
    unreadMessages: boolean;
    arrivalType: number | null;
    balanceOfInvoiceNotZero: boolean;
    balanceReceiptsNotZero: boolean;
    balanceOfPaymentsNotZero: boolean;
    invoiceBalanceByReceipts: boolean;

    constructor() {
        this.productId = '';
        this.slotId = '';
        this.priceTypeId = '';
        this.orderNumber = null;
        this.status = null;
        this.startDate = null;
        this.endDate = null;
        this.startTime = null;
        this.pageIndex = 0;
        this.keyword = '';
        this.orderBy = 'ReservationDate';
        this.asc = false;
        this.arrivalStartDate = null;
        this.arrivalEndDate = null;
        this.unreadMessages = false;
        this.channelId = '';
        this.arrivalType = null;
        this.balanceOfInvoiceNotZero = false;
        this.balanceReceiptsNotZero = false;
        this.balanceOfPaymentsNotZero = false;
        this.invoiceBalanceByReceipts = false;
    }
}

export class RevenueByCurrency {
    currencyId = 0;
    total = '';
}

export class Ticket {
    productAddress = '';
    barcodePath = '';
    productTicketImagePathSmall = '';
    productTicketImagePath = '';
    orderNumber = '';
    productWazeLink = '';
    productGoogleLink = '';
    orderProductStartDate = '';
    productName = '';
    providerName = '';
    ticketCustomers: TicketCustomer[] = [];
    arrivalToday = false;
    scanDate: string | null = null;
}
export class TicketCustomer {
    customerName = '';
    quantity = 0;
}
